import { Box, Container, Typography } from "@mui/material"
import { graphql } from "gatsby"
import i18next from "i18next"
import React from "react"
import AdAppCounting from "../components/AdAppCounting"
import ClusterCategoryTeaser from "../components/ClusterCategoryTeaser"
import CommunitySuccesses from "../components/CommunitySuccesses"
import ContentSearch from "../components/ContentSearch"
import Layout from "../components/Layout"
import PageHeading from "../components/PageHeading"
import Seo from "../components/Seo"
import Textblock from "../components/Textblock"
import withTrans from "../components/withTrans"
import theme from "../theme"

const IndexPage = ({ data, t }) => {
  const allCategories = data.allClusterCategory.nodes

  const getCategoriesByClusterName = name => {
    const clusterCategory = allCategories.find(i => i.name === name)
    if (clusterCategory) {
      return clusterCategory.childrenFoodCategory
    }
    return []
  }

  return (
    <Layout pageName="start" theme={theme}>
      <Container>
        <PageHeading title={t("front_page.heading_1")}>
          {t("front_page.teaser.text")}
        </PageHeading>

        <AdAppCounting my={6} />

        <Box mb={6}>
          <ContentSearch />
        </Box>

        <ClusterCategoryTeaser
          title={t("front_page.parent_category.fruit_vegetables.heading")}
          categories={getCategoriesByClusterName(
            t("front_page.parent_category.fruit_vegetables.heading")
          )}
        />

        <ClusterCategoryTeaser
          title={t("front_page.parent_category.milk_dairy_products.heading")}
          categories={getCategoriesByClusterName(
            t("front_page.parent_category.milk_dairy_products.heading")
          )}
        />

        <ClusterCategoryTeaser
          title={t("front_page.parent_category.meat.heading")}
          categories={getCategoriesByClusterName(
            t("front_page.parent_category.meat.heading")
          )}
        />

        <ClusterCategoryTeaser
          title={t("front_page.parent_category.cereal_products.heading")}
          categories={getCategoriesByClusterName(
            t("front_page.parent_category.cereal_products.heading")
          )}
        />

        <ClusterCategoryTeaser
          title={t("front_page.parent_category.fish_seafood.heading")}
          categories={getCategoriesByClusterName(
            t("front_page.parent_category.fish_seafood.heading")
          )}
        />

        <ClusterCategoryTeaser
          title={t("front_page.parent_category.dishes_meals.heading")}
          categories={getCategoriesByClusterName(
            t("front_page.parent_category.dishes_meals.heading")
          )}
        />

        <ClusterCategoryTeaser
          title={t("front_page.parent_category.drinks_beverages.heading")}
          categories={getCategoriesByClusterName(
            t("front_page.parent_category.drinks_beverages.heading")
          )}
        />

        <ClusterCategoryTeaser
          title={t("front_page.parent_category.candy_sweets.heading")}
          categories={getCategoriesByClusterName(
            t("front_page.parent_category.candy_sweets.heading")
          )}
        />

        <ClusterCategoryTeaser
          title={t("front_page.parent_category.oils_fats_nuts.heading")}
          categories={getCategoriesByClusterName(
            t("front_page.parent_category.oils_fats_nuts.heading")
          )}
        />

        <ClusterCategoryTeaser
          title={t("front_page.parent_category.others.heading")}
          categories={getCategoriesByClusterName(
            t("front_page.parent_category.others.heading")
          )}
        />

        <Textblock mt={6} title={t("front_page.bottom.heading")}>
          <div
            dangerouslySetInnerHTML={{ __html: t("front_page.bottom.text") }}
          />
        </Textblock>

        <Typography sx={{ mt: 8, mb: 4 }} variant="h2" component="div">
          {t("banner.success_story.title")}
        </Typography>

        <CommunitySuccesses />
      </Container>
    </Layout>
  )
}

export default withTrans(IndexPage)

export const Head = () => <Seo title={i18next.t("front_page.title")} />

export const query = graphql`
  query {
    allClusterCategory {
      nodes {
        name
        childrenFoodCategory {
          name
          slug
          key
        }
      }
    }
  }
`
