import styled from "@emotion/styled"
import {
  alpha,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { Box } from "@mui/system"
import { useLocation } from "@reach/router"
import React from "react"
import theme from "../theme"
import KnetIcon from "./KnetIcon"
import withTrans from "./withTrans"

const StyledInputBase = styled(OutlinedInput)(({ theme }) => ({
  color: "inherit",
  borderRadius: "8px",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  "& .MuiOutlinedInput-input": {
    padding: theme.spacing(1.5, 1, 1.5, 2),
    // vertical padding + font size from searchIcon
    width: "100%",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    borderRadius: "8px",
    [theme.breakpoints.up("md")]: {
      width: "35ch",
      "&:focus": {},
    },
  },
  "& .MuiOutlinedInput-input::placeholder": {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#667085",
    opacity: 1,
  },
  "&:hover fieldset": {
    // borderColor: "red !important",
  },
  "& fieldset": {
    borderColor: "#D0D5DD !important",
  },
  "&.Mui-focused fieldset": {
    borderColor: "#667085 !important",
    borderWidth: "1px !important",
  },
}))

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  marginBottom: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("md")]: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0),
    width: "auto",
  },
}))

const ContentSearch = ({ t }) => {
  const location = useLocation()
  const { search } = location
  const query = new URLSearchParams(search).get("s")
  const [searchQuery, setSearchQuery] = React.useState(query || "")
  const upMd = useMediaQuery(theme.breakpoints.up("md"))

  return (
    <Grid
      container
      sx={{
        backgroundColor: "primary.light",
        px: { xs: "1.5rem" },
        py: { xs: "2.5rem", md: theme.spacing(8) },
        borderRadius: 4,
        textAlign: "center",
      }}
    >
      <Grid item xs={12}>
        <Typography variant="subtitle1" component="div" sx={{ mb: "1rem" }}>
          {t("search.content.title")}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          sx={{
            mb: { xs: theme.spacing(2), md: theme.spacing(3) },
            textAlign: { xs: "left", md: "center" },
          }}
        >
          {t("search.content.text")}
        </Typography>
        <Box component="p" mt={2} mb={0}>
          <Search>
            <form action="/search" method="get" autoComplete="off">
              <StyledInputBase
                placeholder={t("search.general.placeholder")}
                fullWidth={upMd ? false : true}
                inputProps={{
                  "aria-label": "search",
                  className: "content-search",
                }}
                value={searchQuery}
                id="search-input"
                name="s"
                onInput={e => setSearchQuery(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <KnetIcon
                      viewBox="0 0 20 20"
                      icon="search"
                      color={theme.palette.text.light}
                    />
                  </InputAdornment>
                }
              />
            </form>
          </Search>
        </Box>
      </Grid>
    </Grid>
  )
}

export default withTrans(ContentSearch)
